
import { defineComponent, ref, watch } from 'vue'
const PAGE_SINGLE_NUM = 10 //  每页数据量
export default defineComponent({
  name: 'pagination',
  props: {
    data: {
      type: Array,
      default: () => {
        return []
      }
    },
    curPage: {
      type: Number,
      default: 1
    }
  },
  emit: ['change'],
  setup(props: any, { emit }) {
    const curPageNumber = ref(props.curPage) // 当前页数
    const pageLength = ref(Math.ceil(props.data.length / PAGE_SINGLE_NUM)) // 总页数
    const dataArr = ref<any[]>([])
    for (let i = 0; i < pageLength.value; i++) {
      let d: any[] = props.data.slice(10 * i, 10 * (i + 1))
      dataArr.value.push(d)
    }
    // 上一页
    const handleLeftClick = () => {
      if (curPageNumber.value === 1) return
      curPageNumber.value--
      emit('change', dataArr.value[curPageNumber.value - 1])
    }
    // 下一页
    const handleRightClick = () => {
      if (curPageNumber.value === pageLength.value) return
      curPageNumber.value++
      emit('change', dataArr.value[curPageNumber.value - 1])
    }
    watch(
      () => props.data,
      () => {
        dataArr.value = []
        pageLength.value = Math.ceil(props.data.length / PAGE_SINGLE_NUM)
        for (let i = 0; i < pageLength.value; i++) {
          let d: any[] = props.data.slice(10 * i, 10 * (i + 1))
          dataArr.value.push(d)
        }
      }
    )
    return {
      curPageNumber,
      pageLength,
      dataArr,
      handleLeftClick,
      handleRightClick
    }
  }
})
