import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-440179cf"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "pagination"
}
const _hoisted_2 = { class: "page-number" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.pageLength > 1)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: _normalizeClass(['button', { disable: _ctx.curPageNumber === 1 }]),
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.handleLeftClick && _ctx.handleLeftClick(...args)))
        }, " 上一页 ", 2 /* CLASS */),
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.curPageNumber), 1 /* TEXT */),
        _createElementVNode("div", {
          class: _normalizeClass(['button', { disable: _ctx.curPageNumber === _ctx.pageLength }]),
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.handleRightClick && _ctx.handleRightClick(...args)))
        }, " 下一页 ", 2 /* CLASS */)
      ]))
    : _createCommentVNode("v-if", true)
}